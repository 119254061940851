.tox-tinymce{
    width: 100%;
}

.react-select--is-disabled{
    &>div{
        background-color: #f2f4f7 !important;
    }
}

.tox-tinymce--disabled{
    div, iframe{
        background-color: #f2f4f7 !important;
    }
}

.initial-image{
    height: 200px;
    object-fit: contain;
    object-position: center left;
}

.block{
    display: block;
}

.mb-30{
    margin-bottom: 30px;
}

.mr-30{
    margin-right: 30px;

    @media only screen and (max-width: 1200px){
        &.lg-mr-0{
            margin-right: 0px;
        }
    }
}

.relative{
    position: relative;
}

.absolute-input-for-warning{
    opacity: 0 !important;
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    z-index: -1 !important;

    &.height-30{
        height: 30% !important;
    }
}

.table.table--bordered{
    button[type="button"]{
        margin-top: 5px;
        margin-bottom: 5px;

        &:not(:last-child){
            margin-right: 10px;
        }
    }

    td[role="cell"]{
        vertical-align: middle;
    }
}

.pagination-numbers{
    display: flex;
    flex-direction: row;
}

.pagination-information{
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 425px){
    .pagination-numbers{
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
    }

    .pagination{
        flex-direction: column;
        align-items: center;
    }

    .pagination-information{
        display: flex;
        flex-direction: row;

        li,div,select{
            margin-left: 0 !important;
        }

        li:first-child{
            margin-right: 10px;
        }
    }
}

.image-showcase{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0%;
    background-color: rgba(0, 0, 0, 0.24);
    transition: all 0.3s ease;
    visibility: hidden;

    img{
        width: 60%;
        height: 60%;
        object-fit: contain;
        transform: translateY(-50px);
        transition: all 0.3s ease;
    }

    &.active{
        opacity: 100%;
        visibility: visible;

        img{
            transform: translateY(0px);
        }
    }
}